<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container" :style="'background: url('+bannerBg+')'">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">Vector软件产品</h1>
        <p data-caption-delay="100" class="wow fadeInRight">为系统架构师、网络设计师、开发和测试工程师提供整个开发过程的全面支持，涵盖系统和单个组件的设计、集成、测试、标定和诊断</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="all">
      <div class="Vector-software-products-background wow fadeInRight">
        <div class="Vector-software-product container">
          <el-row>
            <el-col :span="24" >
              <div class="headquarters">Vector软件产品</div>
            </el-col>
          </el-row>
        </div>
        <div v-for="item in detailedInformation" :key="item.id" class="card-div container wow fadeInLeft">
          <div class="inside-div">
            <el-row>
              <el-col :span="24">
                <p class="product-title">{{item.title}}</p>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <p v-if="item.summary !== ''" class="product-summary">{{item.summary}}</p>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <p v-if="item.detailed !== ''" class="product-detailed">{{item.detailed}}</p>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <p v-if="item.product !== ''" class="product-product">{{item.product}}</p>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <bzInfo/>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/vector/software.less";
import bzInfo from "@/components/BzInfo.vue";
import bannerBg from '@/assets/images/banner-product.jpg';
export default {
  name: "vectorSoftware",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data() {
    return {
      bannerBg: bannerBg,
      activeIndex: '3-1',
      detailedInformation: [
        {
          id: "1",
          title: "CANoe",
          summary: "CANoe是用于ECU、整个网络和分布式系统的测试、仿真、诊断和分析的多总线工具。",
          detailed: "CANoe是用于开发、测试和分析单个ECU和整个ECU网络的综合软件工具。它在整个开发过程中为网络设计师、开发和测试工程师提供支持——从规划到系统级测试。多种变体和功能提供适当的项目支持。因此它的多功能功能和配置选项被全球OEM和供应商成功使用。",
          product: "产品分类为：\n" +
            "CANoe.A429，CANoe.AFDX，CANoe.CANaero，CANoe.CANopen，CANoe.Car2x，CANoe.DiVa，CANoe.Ethernet，CANoe.FlexRay，CANoe.ISO11783，CANoe.J1939，CANoe.LIN，CANoe.MOST，CANoe.J1587"
        },
        {
          id: "2",
          title: "CANape",
          summary: "CANape是测量、校准和记录ECU和ADAS传感器的一个软件。",
          detailed: "CANalyzer是一款操作直观的综合性软件工具，用于分析和激发网络通信。使用CANalyzer检查网络上是否以及正在发生的通信类型。除了发送或记录数据，交互式ECU诊断也是可能的。对于每个应用程序，它都为初学者提供了强大的基本功能，并为有经验的用户提供了广泛的详细功能。",
          product: "产品分类为：\n" +
            "CANape.Option Bypassing，CANape.Option Driver Assistance，CANape.Option Thermodynamic State Charts，CANape.Option vMDM"
        },{
          id: "3",
          title: "CANalyzer",
          summary: "CANalyzer是ECU、整个网络和分布式系统的分析的软件。",
          detailed: "CANoe是用于开发、测试和分析单个ECU和整个ECU网络的综合软件工具。它在整个开发过程中为网络设计师、开发和测试工程师提供支持——从规划到系统级测试。多种变体和功能提供适当的项目支持。因此它的多功能功能和配置选项被全球OEM和供应商成功使用。",
          product: "产品分类为：\n" +
            "CANalyzer.A429，CANalyzer.AFDX，CANalyzer.CANaero，CANalyzeCANopen，CANalyzer.Car2x，CANalyzer.Ethernet，CANalyzer.FlexRay，CANalyzerJ1939，CANalyer.LIN，CANalyzer.MOST，CANalyzer.J1587"
        },
        {
          id: "4",
          title: "CANdelaStudio",
          summary: "CANdelaStudio是创建和编辑正式的ECU诊断规范的工具，是Vector CANdela解决方案的核心组件，支持用户创建和编辑正式的车辆ECU诊断规范。",
          detailed: '',
          product: ''
        },{
          id: "5",
          title: "vTESTstudio",
          summary: "vTESTstudio是一个强大的开发环境，用于创建自动化ECU测试。为了提高测试设计的效率并简化可重用性，它提供基于编程，基于表格和图形测试符号和测试开发方法。",
          detailed: "vTESTstudio可用于所有产品开发阶段：从模型测试到系统验证。由于使用开放接口，vTESTstudio可以简单地整合到现有的工具链中。",
          product: ""
        },
        {
          id: "6",
          title: "ODXStudio",
          summary: "ODXStudio是一个面向用户的ODX格式诊断数据创作工具。作为原生ODX流程的最佳平台，它全面支持所有类别的ODX数据。",
          detailed: "",
          product: ""
        },{
          id: "7",
          title: "vFlash",
          summary: "vFlash是一个非常易于使用的工具，用于通过诊断（例如UDS）对ECU进行编程。由于vFlash插件概念，vFlash可用于客户的所有项目。vFlash已经支持来自100多家汽车制造商的180多种不同的闪存规格，并且可以轻松扩展。",
          detailed: "",
          product: ""
        },
        {
          id: "8",
          title: "Indigo",
          summary: "Indigo是一款易于使用且直观的诊断测试仪，适用于所有诊断任务，可用于车辆开发和车间。由于其模块化设计，Indigo可以单独适应客户需求和公司流程。Indigo可快速了解车辆状态以及各个ECU的详细诊断信息。根据需要，可以定义合适的测试仪配置，将其保存为项目并与同事共享。此外，借助Indigo的独特卖点远程诊断，可以轻松地远程诊断车辆和ECU。",
          detailed: "",
          product: ""
        },{
          id: "9",
          title: "vSignalyzer",
          summary: "vSignalyzer是一种方便的工具，可有效评估所有类型的测量数据。它为测量工程师提供了广泛的数据可视化选项以及手动和自动分析和报告的功能。",
          detailed: "在获取物理值、网络开发、分析和ECU校准过程中记录的测量数据可以从各种文件格式中读取。广泛的函数库和集成的数据挖掘功能让客户可以手动或自动评估客户的测量数据，并专注于重要的事情。集成了强大的打印和报告工具，用于记录结果。",
          product: ""
        },
        {
          id: "10",
          title: "vVIRTUALtarget",
          summary: "vVIRTUALtarget 是用于虚拟化所有典型AUTOSAR项目的单个软件组件和完全配置的ECU的软件。它在整个ECU开发过程中为功能和软件开发人员、软件集成商和测试工程师提供支持。",
          detailed: "",
          product: ""
        },{
          id: "11",
          title: "vMeasure-exp",
          summary: "vMeasure exp是一款灵活的测量软件解决方案，可在实验室、测试台、试驾和耐久性测试期间为客户提供高效支持。使用vMeasure exp，客户可以方便地记录物理值、内部ECU信号以及通过车辆总线发送的信号。为了提供记录数据的信息解释，还需要位置数据 (GPS) 和行进路线的视频数据。测量工程师的典型任务包括测量OBD信号以确定控制单元的状态。",
          detailed: "",
          product: ""
        },
        {
          id: "12",
          title: "ASAP2 Tool-Set描述文件(A2L)",
          summary: "ASAP2 Tool-Set描述文件(A2L) 是使用CCP和XCP作为测量和校准协议的先决条件。ASAP2工具集用于创建和检查此类ECU描述文件。它由ASAP2 Studio通过初始化文件配置的七个命令行程序组成。ASAP2 Studio提供了一个方便的工具，用于通过对话引导的用户界面创建和可视化A2L文件。也可以通过对话框方便地操作命令行程序的所有功能。",
          detailed: "",
          product: ""
        },{
          id: "13",
          title: "DYNA4",
          summary: "DYNA4是一个开放的模拟环境，用于汽车和商用车的虚拟试驾。物理模型包括车辆动力学、动力系统、内燃机、电动机、传感器和交通。使用DYNA4的虚拟试驾有助于安全高效的功能开发和测试。PC上的闭环仿真比实时运行更快，例如用于早期开发阶段（MIL、SIL），或者可以在ECU可用时在硬件在环系统（HIL）上执行。DYNA4的道路基础设施和交通3D环境模拟为环境感知发挥关键作用的辅助和自动驾驶提供了虚拟测试场。",
          detailed: "",
          product: ""
        },
        {
          id: "14",
          title: "DaVinci Developer",
          summary: "DaVinci Developer是一款用于为AUTOSAR Classic ECU设计软件组件（SWC）架构的工具。",
          detailed: "",
          product: ""
        },{
          id: "15",
          title: "PREEvision",
          summary: "PREEvision是一个基于模型的开发环境，支持整个E/E开发——从架构设计一直到最终的线束。",
          detailed: "",
          product: ""
        },
        {
          id: "16",
          title: "VectorCAST",
          summary: "VectorCAST嵌入式软件测试平台是一系列产品，可使整个软件开发生命周期内的测试自动化。本节的目的是提供有关VectorCAST产品的功能、它们如何相互作用以及如何最好地使用它们来完全满足客户自己的软件测试需求的概述。",
          detailed: "",
          product: ""
        },
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    }
  },
  mounted() {
    document.title = "Vector软件产品 - 产品中心 - 东信创智"
    new this.$wow.WOW().init()
  },
}
</script>

<style scoped>

</style>
